.logo-image {
    height: auto;
    width: auto;
    max-height: 50px;
    max-width: 200px;
    float: left;
}

.navbar {
    background-color: #353a42 !important;
    background-image: none !important;
}

.navbar-right-container {
    float: right;
    min-height: 50px !important;
    color: ghostwhite;
}