.report-card .paper {
    height: 134px;
    border-radius: 12px;
    position: relative;
}

.report-card .box {
    width: 64px;
    height: 64px;
    position: relative;
    left: 20px;
    top: -15px;
    border-radius: 10px;
}

.report-card.palisades .box {
    background: linear-gradient(180deg, #63B967 0%, #4BA64F 100%);
}

.report-card.aggregators .box {
    background: linear-gradient(180deg, #439DEE 0%, #1E78E9 100%);
}

.report-card.rules-stats .box {
    background: linear-gradient(180deg, #E63573 0%, #E63573 100%);
}

.report-card.energy-usage .box {
    background: linear-gradient(180deg, #3d3d3d 0%, #090003 100%);
}

.report-card .material-icons {
    font-size: 48px;
    padding: 8px;
    color: white;
}

.content-display {
    padding: 12px;
    text-align: right;
}

.report-card .card-heading {
    font-size: 14px;
    color: #7B809A;
    font-weight: 300;
}

.report-card .count {
    font-weight: 700;
    font-size: 24px;
    color: #344767;
    padding-top: 10px;
}

.status {
    font-size: 16px;
    text-align: left;
    padding: 10px;
    padding-left: 12px;
}

.status.disconnected {
    color: #AF4C4C;
}

.status.connected {
    color: #4CAF50;
}

.large-card-heading {
    text-align: left;
    font-weight: 700;
    color: #344767;
    font-size: 16px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 14px;
}

.large-card-secondary-heading {
    padding: 5px;
    text-align: right;
    font-size: 14px;
    color: grey;
    font-weight: 300;
}

.alerts-list {
    text-align: left;
    font-size: 13px;
    font-weight: 700;
    color: #344767;
}

.alerts-box.spreading-ransomware {
    background: #313136;
}

.alerts-box.boot-process {
    background: #E91F63;
}

.alerts-box.log-verification {
    background: #4CAF50;
}

.alerts-box.unscheduled-bootup {
    background: #318BEB;
}

.alerts-box.anomaly-detection {
    background: #FC9A15;
}

.alerts-box {
    width: 24px;
    height: 24px;
    border-radius: 7px;
}

.alerts-chart-percent {
    text-align: right;
    font-size: 13px;
    font-weight: 400;
    color: #A8B8D8;
    float: right;
}

.report-card.alerts-chart .alerts-chart-paper {
    padding: 12px;
    border-radius: 12px;
}

.alerts-chart-total-count {
    font-size: 15px;
    font-family: sans-serif;
    font-weight: 700;
    fill: #344767;
}

.alerts-chart-total-secondary {
    font-size: 7px;
    font-family: sans-serif;
    font-weight: 400;
    fill: #7B809A;
}

.alerts-type-divider {
    background: #F0F2F5;
    height: 2px;
}

.more.alerts {
    color: #AF4C4C;
}

.less.alerts {
    color: #4CAF50;
}

.alerts {
    color: #7B809A;
    text-align: left;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.recent-events-card {
    border-radius: 12px;

}

.recent-events-paper {
    height: 95%;
    padding: 12px;
    border-radius: 12px !important;
}

.event-card-body {
    height: 40vw;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* Hide scroll bar in Internet Explorer 10+ */
    scrollbar-width: none;
    /* Hide scroll bar in Firefox */
}

.event-card-body::-webkit-scrollbar {
    display: none;
    /* Hide scroll bar in Safari and Chrome */
}

.notification-event-content {
    margin: 14px !important;
    text-align: left !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    color: #344767 !important;
}

/*  To eliminate the extra space on the left of timeline  */
.MuiTimelineItem-root:before {
    content: none !important;
    -webkit-flex: none;
}

.notification-event-content .date-time {
    font-size: 13px;
    font-weight: 400;
    color: #7B809A;
}

.btm-part-alert-card {
    position: absolute;
    bottom: 0;
    width: 100%;
}