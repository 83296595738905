.interfaces-container .dell-connector {
    border-color: #91abb5;
    border-width: 3px;
}

.interfaces-container .lenovo-connector {
    border-color: #af91b5;
    border-width: 3px;
}

.interfaces-container .t-connector {
    border-color: #b1c2a3;
    border-width: 3px;
}

.interfaces-container .can-chip {
    /* border-color: #c4daff; */
    /* border-color: #a4d8db;*/
    border-color: rgba(0, 60, 255, 0.4);
    border-width: 3px;
}

.interfaces-container .barrel-connector {
    border-color: #d0b3ff;
    border-width: 3px;
}

.deployment-status-container.in-stock {
    border-color: #a1ffaa;
    border-width: 3px;
}

.deployment-status-container.under-dev {
    border-color: #ffd3bd;
    border-width: 3px;
}

.deployment-status-container.ready-for-shipping {
    border-color: #cbd8f5;
    border-width: 3px;
}

.deployment-status-container.shipped {
    border-color: #cbd8f5;
    border-width: 3px;
}

.deployment-status-container.received {
    border-color: #cbd8f5;
    border-width: 3px;
}

.deployment-status-container.deployed {
    border-color: #ffccfc;
    border-width: 3px;
}

.deployment-status-container.personal-use {
    border-color: #ffccfc;
    border-width: 3px;
}

.deployment-status-container.unknown {
    border-color: #e0e0e0;
    border-width: 3px;
}

.chip-container.q19-chip {
    border-color: #bee6ca;
    border-width: 3px;
}

.chip-container.q20-chip {
    border-color: #bee2fa;
    border-width: 3px;
}

.chip-container.q21-chip {
    border-color: #dabefa;
    border-width: 3px;
}