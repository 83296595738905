.notification-email-container {
    margin: 20px;
    min-height: 300px;
    text-align: left;
    width: 80% !important;
}


.notification-email-container #notification-buffer-input,
#notification-email-input {
    height: 0.4375em !important;
}