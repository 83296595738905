.side-menu-container {
    background-color: #5B5F66;
    height: 100vh;
    overflow: auto;
    width: 18rem;
    color: ghostwhite;
    padding-top: 51px;
}

.divider-sidemenu {
    border-color: rgb(255 255 255 / 25%) !important;
    border-bottom-width: unset !important;
}

.side-menu-container .menu-button {
    width: calc(100% - 10px);
    margin-left: 5px;
    margin-right: 5px;
}

.side-menu-container .menu-button.selected {
    background: #858C99;
    border-radius: 4px;
}

.side-menu-container .material-icons {
    color: ghostwhite;
}

.side-menu-container .bottom-list {
    color: rgb(248 248 255 / 55%) !important;
}

.side-menu-container .bottom-list .material-icons {
    color: rgb(248 248 255 / 55%) !important;
}

.side-menu-container a:link {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

.side-menu-container a:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}